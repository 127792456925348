/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #F6F6F6;
  font-feature-settings: 'tnum', "tnum";
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #348A9B;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #549da8;
}
a:active {
  color: #226575;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::-moz-selection {
  color: #fff;
  background: #1C3E86;
}
::selection {
  color: #fff;
  background: #1C3E86;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-flex;
  align-items: center;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
          animation-name: antFadeIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
          animation-name: antFadeOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.ant-fade-leave {
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  -webkit-animation-name: antMoveUpIn;
          animation-name: antMoveUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  -webkit-animation-name: antMoveUpOut;
          animation-name: antMoveUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  -webkit-animation-name: antMoveDownIn;
          animation-name: antMoveDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  -webkit-animation-name: antMoveDownOut;
          animation-name: antMoveDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  -webkit-animation-name: antMoveLeftIn;
          animation-name: antMoveLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  -webkit-animation-name: antMoveLeftOut;
          animation-name: antMoveLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  -webkit-animation-name: antMoveRightIn;
          animation-name: antMoveRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  -webkit-animation-name: antMoveRightOut;
          animation-name: antMoveRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
          animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@-webkit-keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@-webkit-keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@-webkit-keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1C3E86;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1C3E86;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
          animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@-webkit-keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1C3E86;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1C3E86;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@-webkit-keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  -webkit-animation-name: antSlideUpIn;
          animation-name: antSlideUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  -webkit-animation-name: antSlideUpOut;
          animation-name: antSlideUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  -webkit-animation-name: antSlideDownIn;
          animation-name: antSlideDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  -webkit-animation-name: antSlideDownOut;
          animation-name: antSlideDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  -webkit-animation-name: antSlideLeftIn;
          animation-name: antSlideLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  -webkit-animation-name: antSlideLeftOut;
          animation-name: antSlideLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  -webkit-animation-name: antSlideRightIn;
          animation-name: antSlideRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  -webkit-animation-name: antSlideRightOut;
          animation-name: antSlideRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
          animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
          animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@-webkit-keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@-webkit-keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  -webkit-animation-name: antZoomIn;
          animation-name: antZoomIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  -webkit-animation-name: antZoomOut;
          animation-name: antZoomOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
          animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
          animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
          animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
          animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
          animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
          animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
          animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
          animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-leave {
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
          animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
          animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
          animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
          animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
          animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@-webkit-keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@-webkit-keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@-webkit-keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@-webkit-keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@-webkit-keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@-webkit-keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 48px;
  padding: 10.4px 15px;
  font-size: 16px;
  border-radius: 4px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn > a:only-child {
  color: currentcolor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #375794;
  border-color: #375794;
  background: #fff;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentcolor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #102761;
  border-color: #102761;
  background: #fff;
}
.ant-btn:active > a:only-child {
  color: currentcolor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #fff;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  border-color: #1C3E86;
  background: #1C3E86;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: #375794;
  background: #375794;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  border-color: #102761;
  background: #102761;
}
.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #375794;
  border-left-color: #375794;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #375794;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #375794;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-btn-ghost > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #375794;
  border-color: #375794;
  background: transparent;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #102761;
  border-color: #102761;
  background: transparent;
}
.ant-btn-ghost:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #375794;
  border-color: #375794;
  background: #fff;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #102761;
  border-color: #102761;
  background: #fff;
}
.ant-btn-dashed:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  border-color: #C53C3C;
  background: #C53C3C;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  border-color: #d16460;
  background: #d16460;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  border-color: #9e282b;
  background: #9e282b;
}
.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #348A9B;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #375794;
  border-color: #375794;
  background: transparent;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #102761;
  border-color: #102761;
  background: transparent;
}
.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #375794;
  border-color: #375794;
  background: transparent;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #102761;
  border-color: #102761;
  background: transparent;
}
.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #C53C3C;
  border-color: #C53C3C;
  background: #fff;
}
.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #d16460;
  border-color: #d16460;
  background: #fff;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #9e282b;
  border-color: #9e282b;
  background: #fff;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  border-color: #C53C3C;
  background: #C53C3C;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  border-color: #d16460;
  background: #d16460;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  border-color: #9e282b;
  background: #9e282b;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #C53C3C;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #375794;
  border-color: #375794;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #102761;
  border-color: #102761;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #d16460;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #9e282b;
  border-color: transparent;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #C53C3C;
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #375794;
  border-color: #375794;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #102761;
  border-color: #102761;
  background: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #d16460;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.018);
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #9e282b;
  border-color: transparent;
  background: rgba(0, 0, 0, 0.028);
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding: 6.4px 0;
  font-size: 16px;
  border-radius: 4px;
  vertical-align: -3px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 48px;
  height: 48px;
  padding: 8.9px 0;
  font-size: 18px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 4px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-icon-only > .anticon {
  display: flex;
  justify-content: center;
}
.ant-btn-icon-only .anticon-loading {
  padding: 0 !important;
}
a.ant-btn-icon-only {
  vertical-align: -1px;
}
a.ant-btn-icon-only > .anticon {
  display: inline;
}
.ant-btn-round {
  height: 40px;
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-lg {
  height: 48px;
  padding: 10.4px 24px;
  font-size: 16px;
  border-radius: 48px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 40px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 48px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizespeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
  cursor: default;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  -webkit-animation: none;
          animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  -webkit-animation: loadingCircle 1s infinite linear;
          animation: loadingCircle 1s infinite linear;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 4px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 4px 4px 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 4px 0 0 4px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-radius: 0 4px 4px 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 4px 0 0 4px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn.ant-btn-background-ghost {
  color: #fff;
  border-color: #fff;
}
.ant-btn.ant-btn-background-ghost,
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
  background: transparent;
}
.ant-btn.ant-btn-background-ghost:hover,
.ant-btn.ant-btn-background-ghost:focus {
  color: #375794;
  border-color: #375794;
}
.ant-btn.ant-btn-background-ghost:active {
  color: #102761;
  border-color: #102761;
}
.ant-btn.ant-btn-background-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #1C3E86;
  border-color: #1C3E86;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #375794;
  border-color: #375794;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #102761;
  border-color: #102761;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #C53C3C;
  border-color: #C53C3C;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #d16460;
  border-color: #d16460;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #9e282b;
  border-color: #9e282b;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #C53C3C;
  border-color: #C53C3C;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #d16460;
  border-color: #d16460;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #9e282b;
  border-color: #9e282b;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #C53C3C;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #d16460;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #9e282b;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  background: #f5f5f5;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentcolor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.01px !important;
  line-height: 38px;
}
a.ant-btn-disabled {
  cursor: not-allowed;
}
a.ant-btn-disabled > * {
  pointer-events: none;
}
a.ant-btn-disabled,
a.ant-btn-disabled:hover,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:active {
  color: rgba(0, 0, 0, 0.25);
  border-color: transparent;
  background: transparent;
  text-shadow: none;
  box-shadow: none;
}
a.ant-btn-disabled > a:only-child,
a.ant-btn-disabled:hover > a:only-child,
a.ant-btn-disabled:focus > a:only-child,
a.ant-btn-disabled:active > a:only-child {
  color: currentcolor;
}
a.ant-btn-disabled > a:only-child::after,
a.ant-btn-disabled:hover > a:only-child::after,
a.ant-btn-disabled:focus > a:only-child::after,
a.ant-btn-disabled:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
a.ant-btn-lg {
  line-height: 46px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  margin-right: -1px;
}
.ant-btn-compact-item:not(.ant-btn-compact-last-item).ant-btn-compact-item-rtl {
  margin-left: -1px;
}
.ant-btn-compact-item:hover,
.ant-btn-compact-item:focus,
.ant-btn-compact-item:active {
  z-index: 2;
}
.ant-btn-compact-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-last-item).ant-btn {
  border-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-first-item:not(.ant-btn-compact-last-item):not(.ant-btn-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-last-item:not(.ant-btn-compact-first-item):not(.ant-btn-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-first-item:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item.ant-btn.ant-btn-compact-item-rtl.ant-btn-compact-last-item:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-icon-only.ant-btn-compact-item {
  flex: none;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: 1px;
  height: calc(100% + 1px * 2);
  background-color: #375794;
  content: ' ';
}
.ant-btn-compact-item-rtl.ant-btn-compact-first-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-compact-last-item.ant-btn-compact-item-rtl:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-first-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-sm.ant-btn-compact-last-item.ant-btn-compact-item-rtl.ant-btn-sm:not(.ant-btn-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-compact-item-rtl.ant-btn-primary:not([disabled]) + .ant-btn-compact-item-rtl.ant-btn-primary:not([disabled])::after {
  right: -1px;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-last-item) {
  margin-bottom: -1px;
}
.ant-btn-compact-vertical-item:hover,
.ant-btn-compact-vertical-item:focus,
.ant-btn-compact-vertical-item:active {
  z-index: 2;
}
.ant-btn-compact-vertical-item[disabled] {
  z-index: 0;
}
.ant-btn-compact-vertical-item:not(.ant-btn-compact-vertical-first-item):not(.ant-btn-compact-vertical-last-item) {
  border-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-first-item:not(.ant-btn-compact-vertical-last-item) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-compact-vertical-last-item:not(.ant-btn-compact-vertical-first-item) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) {
  position: relative;
}
.ant-btn-compact-vertical-item.ant-btn-primary:not([disabled]) + .ant-btn-compact-vertical-item.ant-btn-primary:not([disabled])::after {
  position: absolute;
  top: -1px;
  left: -1px;
  display: inline-block;
  width: calc(100% + 1px * 2);
  height: 1px;
  background-color: #375794;
  content: ' ';
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #375794;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #375794;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}

